export default {
	name: 'Underlying',
	created () {
		// this.$$.navbarAnimation()
		this.$nextTick(function () {
			if (this.$route.params.ric !== undefined) {
				localStorage.setItem('underlyingRic', this.$route.params.ric)
			}
			this.ariseCombobox('#UnderlyingcomboboxTotal')
			this.GetScreenerData()
			$('.selector_ma').selectmenu({
				width: '100%'
			})
			$('.selector_bb').selectmenu({
				width: '100%'
			})
			const _this = this
			$('.input_rsi').change(function (e) {
				_this.getUnderlyingChartData()
			})
			$('.selector_ma').on('selectmenuchange', function () {
				_this.getUnderlyingChartData()
			})
			$('.selector_bb').on('selectmenuchange', function () {
				if ($('.checkbox_bb').is(':checked')) { _this.getUnderlyingChartData() }
			})
			$('.checkbox_bb').change(function (e) {
				_this.getUnderlyingChartData()
			})
			// $(".selector_ma").selectmenu("disable");;
			$('.selector_ma').selectmenu('enable')
			$('.ui-selectmenu-icon').removeClass('ui-icon-triangle-1-s')
			$('.ui-selectmenu-icon').addClass('ui-icon-caret-1-s')
			this.WarrantNameFixed()
		})
	},
	mounted () {
		this.$nextTick(function () {

		})
	},
	data () {
		return {
			ricData: [],
			tableHeadData: [
				this.$t('WSe.WN'),
				this.$t('WTs.Is'),
				this.$t('Und.WT'),
				this.$t('hp.Vo') + ' (\'000)',
				this.$t('hp.BP') + ' (' + this.$t('hp.RM') + ')',
				this.$t('hp.Ch') + ' (%)',
				this.$t('WSe.EP'),
				this.$t('WSe.ER'),
				this.$t('hp.Ex')
			],
			current_dsply_nmll: '',
			tableContentData: [],
			Hname: '',
			ConDataUl: [{
				name: this.$t('Und.LC'),
				data: ''
			},
			{
				name: this.$t('hp.Ch'),
				data: ''
			},
			{
				name: this.$t('hp.Bid'),
				data: ''
			},
			{
				name: this.$t('hp.Vo') + ' (\'000)',
				data: ''
			}
			],
			underlyingDataList: [],
			chartDate: 'm',
			chartId: 2,
			chartRic: '',
			triIf: false,
			dataIf: false,
			negitive: true
		}
	},
	computed: {

	},
	watch: {
		underlyingDataList () {
			if (this.underlyingDataList.length > 0) {
				let fullchartArr = []
				let chartArr = []
				$.each(this.underlyingDataList, (index, datas) => {
					let arr = []
					if (this.chartDate == 'd') {
						arr.push(datas.time)
					} else {
						arr.push(datas.date)
					}
					arr.push(datas.open)
					arr.push(datas.high)
					arr.push(datas.low)
					arr.push(datas.close)
					arr.push(datas.volume)
					fullchartArr.push(arr)
					if (this.chartDate == 'd') {
						chartArr.push(arr)
					} else if (this.chartDate == 'w') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 7
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == 'm') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 30
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == '6m') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 30 * 6
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == 'y') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 365
						if (currentdate - diff < jsondate) chartArr.push(arr)
					}
				})
				this.underlyingChartLoad(chartArr, fullchartArr)
				this.dataIf = true
			} else {
				this.chartDate = 'm'
				this.switchTimeData(2, this.chartDate, true)
			}
		}
	},
	methods: {
		// 获取ric列表
		GetScreenerData: function () {
			$.ajax({
				type: 'GET',
				url: this.$$.mibb + 'GetScreenerData',
				data: {
					token: 'webkey',
					init: '1'
				},
				dataType: 'json',
				success: data => {
					if (data.status == 'SUCCESS') {
						this.ricData = data.underlyings
						if (localStorage.getItem('underlyingRic') == null) {
							this.getMarketData(this.ricData[0].underlying_ric)
						} else {
							this.getMarketData(localStorage.getItem('underlyingRic'))
						}
					}
				},
				error: function (error) {
					console.log(error)
				}
			})
		},
		// market data
		getMarketData (ric) {
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb +
					'GetMarketData?token=webkey&type=underlyingdata&ric=' +
					ric,
				success: data => {
					if (data.status == 'SUCCESS') {
						$('#Underlying').find('.search').find('input').val(`(${data[ric].DSPLY_NMLL}) ${data[ric].DSPLY_NAME}`)
						this.Hname = `${data[ric].DSPLY_NAME} (${data[ric].DSPLY_NMLL})`
						this.ConDataUl[0].data = (this.$$.curreryRetrun(data[ric].CURRENCY) == 'IDR' ? 'Rp' : this.$$.curreryRetrun(data[ric].CURRENCY)) + ' ' + this.$$.formatNumber(data[ric].HST_CLOSEBID, 0, 1)
						this.ConDataUl[1].data = `${this.$$.formatNumber(this.$$.changeRate(data[ric].BID_PCTCHNG), 1, 1)} %`
						this.negitive = this.$$.changeRate(data[ric].BID_PCTCHNG) >= 0
						this.ConDataUl[2].data = (this.$$.curreryRetrun(data[ric].CURRENCY) == 'IDR' ? 'Rp' : this.$$.curreryRetrun(data[ric].CURRENCY)) + ' ' + this.$$.formatNumber(data[ric].BID, 0, 1)
						this.ConDataUl[3].data = this.$$.formatNumber(this.$$.clearRM(data[ric].ACVOL_1), 0, 1)
						this.chartRic = ric
						this.current_dsply_nmll = data[ric].DSPLY_NMLL
						this.getUnderlyingChartData()
						this.getSearchData(data[ric].name)
					}
				},
				error: function (XMLHttpRequest) { }
			})
		},
		getSearchData (ric) {
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetScreenerData',
				data: {
					token: 'webkey',
					underlying: ric,
					type: 'all',
					issuer: 'all',
					maturity: 'all',
					moneyness: 'all',
					effectiveGearing: 'all',
					expiry: 'all',
					sortBy: 'isr',
					sortOrder: 'desc'
				},
				success: (data) => {
					if (data.status == 'SUCCESS') {
						this.tableContentData = data.data
					}
				},
				error: function (XMLHttpRequest) { }
			})
		},
		// 转换时间数据
		switchTimeData: function (id, time, tri) {
			const _this = this
			this.chartId = id
			$('#Underlying').find('.ChartButtonBox').find('div').eq(id).find('.btn').addClass('mark')
			$('#Underlying').find('.ChartButtonBox').find('div').eq(id).siblings('div').find('.btn').removeClass('mark')
			this.chartDate = time
			if (this.chartDate == 'd') {
				$('.selector_ma').selectmenu('disable')
			} else {
				$('.selector_ma').selectmenu('enable')
			}
			if (tri) {
				if (this.chartDate == 'd') {
					this.dataIf = false
					this.getUnderlyingChartData()
					this.triIf = true
				} else {
					if (this.triIf) {
						this.triIf = false
						this.getUnderlyingChartData()
					}
				}
			}
			if (this.chartDate == 'd') {
				return
			}
			if (this.dataIf) {
				let fullchartArr = []
				let chartArr = []
				$.each(this.underlyingDataList, (index, datas) => {
					let arr = []
					arr.push(datas.date)
					arr.push(datas.open)
					arr.push(datas.high)
					arr.push(datas.low)
					arr.push(datas.close)
					arr.push(datas.volume)
					fullchartArr.push(arr)
					if (this.chartDate == 'w') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 7
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == 'm') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 30
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == '6m') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 30 * 6
						if (currentdate - diff < jsondate) chartArr.push(arr)
					} else if (this.chartDate == 'y') {
						var currentdate = new Date().getTime()
						var jsondate = new Date(datas.date).getTime()
						var diff = 1000 * 60 * 60 * 24 * 365
						if (currentdate - diff < jsondate) chartArr.push(arr)
					}
				})
				this.underlyingChartLoad(chartArr, fullchartArr)
			}
		},
		// underlying chart
		getUnderlyingChartData () {
			const _this = this
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb +
					'GetChartData?token=webkey&type=1&period=' +
					this.chartDate +
					'&ric=' +
					this.chartRic,
				success: (res) => {
					if (res.status == 'SUCCESS') {
						this.underlyingDataList = res.underlyingDataList
						this.switchTimeData(this.chartId, this.chartDate)
					}
				},
				error: function (XMLHttpRequest) { }
			})
		},
		// 显示chart图
		underlyingChartLoad (chartArr, fullchartArr) {
			let _this = this;
			$('#UnderlyingChart').empty() // 先清空

			anychart.onDocumentReady(function () {
				// create data table on loaded data
				var dataTable = anychart.data.table()
				dataTable.addData(fullchartArr)
				var candlestickMapping = dataTable.mapAs({
					open: 1,
					high: 2,
					low: 3,
					close: 4
				});
				// var scrollerData = anychart.data.table();
				// scrollerData.addData(chartArr);
				// var scollerMapping = scrollerData.mapAs({
				// 	open: 1,
				// 	high: 2,
				// 	low: 3,
				// 	close: 4
				// });

				// map data for volume series
				var valueMapping = dataTable.mapAs({
					value: 5
				});

				// create stock chart
				var chart = anychart.stock();
				chart.padding(0, 0, 0, 0)

				var candlestickPlot = chart.plot(0);
				var candlestickSeries = candlestickPlot.candlestick(candlestickMapping);
				candlestickPlot.xGrid().enabled(true);
				candlestickPlot.yGrid().enabled(true);
				candlestickPlot.xAxis().showHelperLabel(false);
				candlestickPlot
					.xAxis()
					.labels()
					.enabled(false);
				candlestickPlot.xAxis().height(0);
				candlestickPlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)
				candlestickPlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)
				candlestickPlot
					.yAxis()
					.labels()
					.format(function () {
						return _this.$$.formatNumber(this.value, 3, 0)
					})

				candlestickSeries.name(_this.current_dsply_nmll)
				candlestickSeries.risingFill('#009900')
				candlestickSeries.fallingFill('#FF0000')
				candlestickSeries.legendItem().iconType('risingfalling')
				var candlestickSeriestooltip = candlestickSeries.tooltip()
				candlestickSeriestooltip.enabled(true)

				candlestickSeriestooltip.format(function (e) {
					var close = this.close
					var open = this.open
					var high = this.high
					var low = this.low
					return (
						'Close: ' +
						_this.$$.formatNumber(close, 0, 0) +
						'\nOpen: ' +
						_this.$$.formatNumber(open, 0, 0) +
						'\nHigh: ' +
						_this.$$.formatNumber(high, 0, 0) +
						'\nLow: ' +
						_this.$$.formatNumber(low, 0, 0)
					)
				})
				// create stock end

				// create and setup volume plot --1 start
				var volumePlot = chart.plot(1)
				volumePlot.height('20%')
				volumePlot.xGrid().enabled(true)
				volumePlot.yGrid().enabled(true)
				volumePlot.xAxis().showHelperLabel(false)
				volumePlot
					.xAxis()
					.labels()
					.enabled(false)
				volumePlot.xAxis().height(0)
				volumePlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)
				volumePlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)

				var volumeSeries = volumePlot.column(valueMapping)
				volumeSeries.name('Volume')
				volumeSeries.fill('#5d5d5d')

				volumePlot
					.yAxis()
					.labels()
					.format(function () {
						var value = this.value
						value = parseInt(value) / 1000
						return _this.$$.formatNumber(value, 1, 0) + 'K'
					})

				var volumeSeriestooltip = volumeSeries.tooltip()
				volumeSeriestooltip.enabled(true)

				volumeSeriestooltip.format(function (e) {
					var value = this.value
					value = parseInt(value) / 1000
					return 'Volume: ' + _this.$$.formatNumber(value, 1, 0) + 'K'
				})
				// create and setup volume plot --1 end

				// create and setup volume plot --2 start
				var rsiPlot = chart.plot(2)
				rsiPlot.height('20%')
				rsiPlot.xGrid().enabled(true)
				rsiPlot.yGrid().enabled(true)
				rsiPlot.xAxis().showHelperLabel(false)
				rsiPlot
					.xAxis()
					.labels()
					.enabled(true)
				rsiPlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)
				rsiPlot
					.yAxis()
					.labels()
					.enabled(true)
					.fontColor('#181515')
					.fontSize(11)
				rsiPlot.yScale().maximum(100)
				rsiPlot.yScale().minimum(0)

				var rsi = rsiPlot
					.rsi(candlestickMapping, $('.input_rsi').val())
					.series()
				rsi.stroke('#ffc600')

				if (_this.chartDate == 'd') {
					rsiPlot
						.xAxis()
						.labels()
						.format(function () {
							// Set format for date time
							var hour = new Date(this.tickValue).getUTCHours().toString().padStart(2, '0')
							var min = new Date(this.tickValue).getUTCMinutes().toString().padStart(2, '0')
							return (Array(2).join(0) + hour).slice(-2) + ':' + (Array(2).join(0) + min).slice(-2)
							// return anychart.format.dateTime(
							//     _this.$$.changeToLocalTimeT(this.tickValue),
							//     "HH:mm"
							// );
						})
				} else {
					rsiPlot
						.xAxis()
						.labels()
						.format(function () {
							// Set format for date time
							return anychart.format.dateTime(
								_this.$$.changeToLocalTime(this.value),
								'dd MMM'
							)
						})
				}

				var rsitooltip = rsi.tooltip()
				rsitooltip.enabled(true)
				rsitooltip.format(function (e) {
					var seriesName = this.seriesName
					var value = this.value
					return seriesName + ': ' + _this.$$.formatNumber(this.value, 1, 0)
				})
				// create and setup volume plot --2 end

				// sma
				if (_this.chartDate !== 'd') {
					var smaline = candlestickPlot
						.sma(candlestickMapping, $('.selector_ma').val())
						.series()
					smaline
						.name('SMA(' + $('.selector_ma').val() + ')')
						.stroke('#ff6d00')

					var smalinetooltip = smaline.tooltip()
					smalinetooltip.enabled(true)

					smalinetooltip.format(function (e) {
						var seriesName = this.seriesName
						var value = this.value
						return seriesName + ': ' + _this.$$.formatNumber(this.value, 0, 0)
					})
				}

				// bolling
				if ($('.checkbox_bb').is(':checked')) {
					var bbands = candlestickPlot.bbands(
						candlestickMapping,
						$('.selector_bb').val(),
						'spline',
						'spline',
						'spline'
					)
					bbands.upperSeries().stroke('#0000ff')
					bbands.middleSeries().stroke('#00ff00')
					bbands.lowerSeries().stroke('#0000ff')
					bbands.rangeSeries().fill('#ffd54f 0.2')

					var bbandsupperSeriestooltip = bbands.upperSeries().tooltip()
					var bbandsmiddleSeriestooltip = bbands.middleSeries().tooltip()
					var bbandslowerSeriestooltip = bbands.lowerSeries().tooltip()
					var bbandsrangeSeriestooltip = bbands.rangeSeries().tooltip()
					bbandsupperSeriestooltip.enabled(true)
					bbandsmiddleSeriestooltip.enabled(true)
					bbandslowerSeriestooltip.enabled(true)
					bbandsrangeSeriestooltip.enabled(false)

					bbandsupperSeriestooltip.format(function (e) {
						var seriesName = this.seriesName
						var value = this.value
						return seriesName + ': ' + _this.$$.formatNumber(this.value, 0, 0)
					})

					bbandsmiddleSeriestooltip.format(function (e) {
						var seriesName = this.seriesName
						var value = this.value
						return seriesName + ': ' + _this.$$.formatNumber(this.value, 0, 0)
					})

					bbandslowerSeriestooltip.format(function (e) {
						var seriesName = this.seriesName
						var value = this.value
						return seriesName + ': ' + _this.$$.formatNumber(this.value, 0, 0)
					})
				}

				candlestickPlot.legend().useHtml(true)
				volumePlot.legend().useHtml(true)
				rsiPlot.legend().useHtml(true)

				// configure the format of legend items
				candlestickPlot.legend().itemsFormat(function () {
					var series = this.series

					if (series.getType() == 'candlestick') {
						return (
							series.name() +
							': (O: ' +
							_this.$$.formatNumber(this.open, 0, 0) +
							', H: ' +
							_this.$$.formatNumber(this.high, 0, 0) +
							', L: ' +
							_this.$$.formatNumber(this.low, 0, 0) +
							', C: ' +
							_this.$$.formatNumber(this.close, 0, 0) +
							')'
						)
					} else if (series.getType() == 'line') {
						return (
							series.name() + ': ' + _this.$$.formatNumber(this.value, 0, 0)
						)
					} else if (series.getType() == 'range-area') {
						return (
							series.name() +
							': (H: ' +
							_this.$$.formatNumber(this.high, 0, 0) +
							', H: ' +
							_this.$$.formatNumber(this.high, 0, 0) +
							')'
						)
					} else if (series.getType() == 'spline') {
						return (
							series.name() + ': ' + _this.$$.formatNumber(this.value, 0, 0)
						)
					}
				})

				volumePlot.legend().itemsFormat(function () {
					var series = this.series
					var value = this.value
					value = parseInt(value) / 1000

					return (
						series.name() + ': ' + _this.$$.formatNumber(value, 1, 0) + 'K'
					)
				})

				rsiPlot.legend().itemsFormat(function () {
					var series = this.series
					return series.name() + ': ' + _this.$$.formatNumber(this.value, 1, 0)
				})

				// create scroller series
				chart.scroller().area(candlestickMapping)
				chart.scroller().height('20px')
				if (_this.chartDate == 'd') {
					var openmarket = false
					var fun_datenum = 1
					// while (openmarket == false) {
					// 	fun_datenum--
					// 	var tempdate = new Date(_this.fun_date(fun_datenum) + ' 00:00:00 GMT+0')
					// 	// for (var k = 0; k < fullchartArr.length; k++) {
					// 	// 	if (fullchartArr[k][0] > tempdate) {
					// 	// 		openmarket = open
					// 	// 	}
					// 	// }
					// }
					var tempdate = new Date(_this.fun_date(fun_datenum) + ' 00:00:00 GMT+0')
					chart.selectRange(tempdate, fullchartArr[0][0]);
				} else if (_this.chartDate == 'w') {
					chart.selectRange(_this.fun_date(-7), _this.getNowFormatDate())
				} else if (_this.chartDate == 'm') {
					chart.selectRange(_this.getPassFormatDate(), _this.getNowFormatDate())
				} else if (_this.chartDate == '6m') {
					chart.selectRange(_this.getPassFormatDate6(), _this.getNowFormatDate())
				} else {
					chart.selectRange(_this.getPassYearFormatDate(), _this.getNowFormatDate())
				}
				chart.scroller().enabled(true)
				chart.scroller().orientation('top')
				chart.scroller().minHeight(35)
				chart.scroller().thumbs().autoHide(true)
				chart.scroller().selectedFill('#ffc600', '0.3')
				// var xScroller = chart.scroller();
				// xScroller.orientation('top');
				// enable chart scroll
				var listener = function (e) {
					return true
				}
				chart.listen('selectedrangechangestart', listener)
				chart.listen('selectedrangebeforechange', listener)
				chart.listen('selectedrangechange', listener)
				chart.listen('selectedrangechangefinish', listener)

				// set container id for the chart
				chart.container('UnderlyingChart')

				// initiate chart drawing
				chart.draw()
				$('.anychart-credits').remove()
			})
		},
		// 显示chart图
		getNowFormatDate () {
			var date = new Date()
			var seperator1 = '-'
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var strDate = date.getDate()
			if (month >= 1 && month <= 9) {
				month = '0' + month
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate
			}
			var currentdate = year + seperator1 + month + seperator1 + strDate
			return currentdate
		},
		// 1m
		getPassFormatDate () {
			var nowDate = new Date()
			var date = new Date(nowDate)
			date.setDate(date.getDate() - 30)
			var seperator1 = '-'
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var strDate = date.getDate()
			if (month >= 1 && month <= 9) {
				month = '0' + month
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate
			}
			var currentdate = year + seperator1 + month + seperator1 + strDate
			return currentdate
		},
		// 6m
		getPassFormatDate6 () {
			var nowDate = new Date()
			var date = new Date(nowDate)
			date.setDate(date.getDate() - (6 * 30))
			var seperator1 = '-'
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var strDate = date.getDate()
			if (month >= 1 && month <= 9) {
				month = '0' + month
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate
			}
			var currentdate = year + seperator1 + month + seperator1 + strDate
			return currentdate
		},
		// 1y
		getPassYearFormatDate () {
			var nowDate = new Date()
			var date = new Date(nowDate)
			date.setDate(date.getDate() - 365)
			var seperator1 = '-'
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var strDate = date.getDate()
			if (month >= 1 && month <= 9) {
				month = '0' + month
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = '0' + strDate
			}
			var currentdate = year + seperator1 + month + seperator1 + strDate
			return currentdate
		},
		// 1w +往后 -往前
		fun_date (aa) {
			var date2 = new Date()
			date2.setDate(date2.getDate() + aa)
			var time2 =
				date2.getFullYear() +
				'-' +
				(date2.getMonth() + 1) +
				'-' +
				date2.getDate()
			return time2.replace(/\-/g, '/')
		},
		//  WarrantName 固定
		WarrantNameFixed: function () {
			const dody = $('#Underlying').find('.message')
			const dodyIN = dody.find('.underly-input')
			$('#Underlying').find('.tableBox').scroll(function () {
				if ($(this).scrollLeft() > 0) {
					$('.fixedName').show()
				} else {
					$('.fixedName').hide()
				}
				$('.fixedName').css({
					top: dody.find('ul').outerHeight(true) + dody.find('.ChartBox').outerHeight(true) + dodyIN.eq(0).outerHeight(true) + dodyIN.eq(1).outerHeight(true) + dodyIN.eq(2).outerHeight(true) + 30
				})
				$('.fixedName').width($(this).find('thead').find('tr').find('th').eq(0).outerWidth(true))
			})
		},
		// 出现combobox
		ariseCombobox: function (id) {
			const _this = this
			this.$$.jqcombobox(id)
			$(id).combobox({
				select: function (event, ui) {
					localStorage.setItem('underlyingRic', this.value)
					_this.getMarketData(this.value)
				}
			})
			$(id).parent().find('input').attr('placeholder', this.$t('WCn.SU'))
		}
	}
}
